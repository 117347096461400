import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'
import Navigation from '@components/Navigation'
import attachSocialIcons from '@helpers/attachSocialIcons'

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`
  },
  occupation: {
    mb: 4
  },
  specialty: {
    color: `text`,
    mb: 4
  },
  social: [
    {
        name: `YouTube`,
        url: `https://www.youtube.com/channel/UCQQYOCUCem_PzjqFkCIM5eA`
    },
    {
        name: `Linkedin`,
        url: `https://www.linkedin.com/in/srikanthgunnala/`
    },
    {
        name: `Github`,
        url: `https://github.com/gunnala-sri`
    },
    {
        name: `Twitter`,
        url: `https://twitter.com/gunnalasri`
    } 
]
}

export default () => (
  <>
    <Heading variant='h1'>
      Hi, I'm <Text sx={styles.author}>Sri Gunnala</Text>.
    </Heading>
    <Heading variant='h1' sx={styles.occupation}>
      Tech Enthusiast
    </Heading>
    <Heading variant='h3' sx={styles.specialty}>
      Specialized in Microsoft .NET and Azure
    </Heading>
    {/* <Box variant='buttons.group'>
      <Button as={Link} to='/contact'>
        Contact Me
      </Button>
      <Button variant='white' as={Link} to='/about'>
        About Me
      </Button>
    </Box> */}
     <Navigation
              variant='horizontal'
              items={attachSocialIcons(styles.social)}
              iconOnly
            />
  </>
)
